/* 
    -- Table of Content --

    * 01 - Menu
    * 02 - Responsive Sidebar Menu
    * 03 - Global Color
    * 04 - Header
    * 05 - Section Header
    * 06 - Left Sidebar
    * 07 - Hero Section
    * 08 - Home 3
    * 09 - Home 5
    * 10 - About
    * 11 - Resume
    * 12 - Services
    * 13 - Skills
    * 14 - Portfolio
    * 15 - Testimonial
    * 16 - Client
    * 17 - Pricing Table
    * 18 - Contact
    * 19 - Breadcrumb
    * 20 - Blog items
    * 21 - Blog Style 2
    * 22 - Blog Details
    * 23 - Comment
    * 24 - Comment Form
    * 25 - Footer
    * @Keyframe

*/

:root {
  --primary_color: #28e98c;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &::before,
  &::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
}

body {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  scroll-behavior: smooth;
  color: #999999;
  background: #1f1f1f;
  line-height: 1.7;
  overflow-x: hidden;
  height: 100vh;
}

.bg-white {
  background: #fff;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

.text-right {
  text-align: right;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.content-width {
  max-width: 770px;
  width: 100%;
  margin-left: auto;
}

.theme-btn {
  background: var(--primary_color);
  color: #000000;
  display: block;
  text-align: center;
  padding: 11px 58px 10px 58px;
  display: inline-flex;
  align-items: center;
  border-radius: 30px;
  justify-content: center;
  text-transform: uppercase;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  border: 2px solid var(--primary_color);

  i {
    font-size: 24px;
    margin-right: 10px;
    display: block;
    margin-bottom: 3px;
  }

  &:hover {
    background: none;
    color: var(--primary_color);
  }
}

.custom-container {
  max-width: 1130px;
  padding: 0 15px;
  margin: auto;
}

.subtitle {
  font-size: 12px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 300;
  margin: 0;
  border: 1px solid #565656;
  padding: 9px 20px;
  border-radius: 30px;
  margin-bottom: 53px;
  display: inline-flex;
  align-items: center;

  i {
    margin-bottom: 1px;
    font-size: 14px;
    margin-right: 10px;
  }
}

.page-loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;

  .bounceball {
    position: relative;
    display: inline-block;
    height: 37px;
    width: 15px;

    &:before {
      position: absolute;
      content: "";
      display: block;
      top: 0;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #fff;
      transform-origin: 50%;
      animation: bounceLoader 500ms alternate infinite ease;
    }
  }
}

/* Icon Menu */

.icon-menu {
  position: absolute;
  right: 68px;
  top: 60px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  border: 1px solid #575757;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  transition: 0.3s;
  background: #1f1f1f;
  z-index: 99;

  span {
    width: 20px;
    display: block;
    height: 2px;
    background: #fff;
    transition: 0.3s;
  }

  &:hover {
    border-color: var(--primary_color);

    span {
      background: var(--primary_color);
    }
  }
}

#scroll-section {
  /* overflow-x: hidden !important;
      overflow-y: auto !important; */
  /* width: 100%; */
  /* height: 100%; */
  /* height: 100vh; */
}

.scroll-content {
  /* transform: translate(0) !important; */
}

main.deniz-main {
  /* inset: 0px;
      width: 100%;
      height: 100vh;
      position: fixed; */
  /* overflow: hidden; */
}

.page-section {
  overflow: hidden;
}

/* 01 - Menu */

.menu {
  flex-direction: column;
  border: 1px solid #575757;
  border-radius: 30px;
  position: fixed;
  right: 68px;
  top: 322px;

  /* width: 55px; */
  text-align: center;
  gap: 20px;
  padding: 24px 0;
  background: #1f1f1f;
  z-index: 20;

  li {
    .scroll-to {
      display: block;
      position: relative;

      /* padding: 10px 10px; */
      width: 55px;
      transition: 0.3s;
      color: #999999;
      cursor: pointer;
    }

    a {
      display: block;
      position: relative;

      /* padding: 10px 10px; */
      width: 55px;
      transition: 0.3s;
      color: #999999;
      cursor: pointer;

      &.active,
      &:hover {
        color: var(--primary_color);
      }
    }

    .scroll-to {
      &.active {
        color: var(--primary_color);
      }

      &:hover {
        color: var(--primary_color);

        span {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    a:hover span {
      opacity: 1;
      visibility: visible;
    }

    .scroll-to i,
    a i {
      font-size: 20px;
      display: block;
    }

    .scroll-to span,
    a span {
      position: absolute;
      transition: 0.3s;
      font-size: 12px;
      background: #404042;
      right: 100%;
      color: #fff;
      display: block;
      padding: 3px 8px;
      border-radius: 5px;
      visibility: hidden;
      opacity: 0;
    }

    .scroll-to span::before,
    a span::before {
      content: "";
      width: 10px;
      height: 10px;
      background: #404042;
      position: absolute;
      right: -3px;
      top: 50%;
      transform: rotate(54deg) skew(-8deg, -39deg);
      margin-top: -5px;
      z-index: -1;
    }
  }
}

/* 02 - Responsive Sidebar Menu */

.responsive-sidebar-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.global-color .inner .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #565656;
  opacity: 0.5;
}

.responsive-sidebar-menu {
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #565656;
    opacity: 0.5;
  }

  .sidebar-menu-inner {
    max-width: 345px;
    width: 100%;
    margin-left: auto;
    background: #191919;
    height: 100%;
    overflow-x: hidden;
    padding-top: 50px;
    margin-right: -250px;
    transition: 0.3s;
  }

  &.active .sidebar-menu-inner {
    margin-right: 0;
  }

  .sidebar-menu-inner {
    .menu-wrap {
      width: 46%;
      margin: auto;

      p {
        font-size: 18px;
      }

      .menu {
        position: relative;
        right: auto;
        left: 0;
        top: 0;
        transform: translateY(0);
        background: none;
        border-radius: 0;
        border: none;
        margin-bottom: 30px;

        li a {
          display: flex;
          align-items: center;
          gap: 10px;

          &:hover span {
            color: #fff;
          }

          i {
            margin-bottom: 2px;
          }

          span {
            position: relative;
            right: 0;
            opacity: 1;
            visibility: visible;
            color: #999999;
            background: none;
            margin: 0;
            padding: 0;
            border-radius: 0;

            &::before {
              display: none;
            }
          }

          &.active span {
            color: #fff;
          }
        }
      }
    }

    .global-color-option {
      width: 46%;
      margin: auto;
    }

    .sidebar-social {
      width: 46%;
      margin: auto;

      ul {
        gap: 15px;

        li a {
          color: #999999;
          transition: 0.3s;
          font-size: 16px;

          &:hover {
            color: var(--primary_color);
          }
        }
      }
    }
  }
}

/* 03 - Global Color */

.global-color {
  position: fixed;
  left: 0;
  top: 50px;
  z-index: 100;

  .inner {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s;
  }

  &.active .inner {
    opacity: 1;
    visibility: visible;
  }

  .setting-toggle {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    background: #1f1f1f;
  }

  .inner .global-color-option {
    .close-settings {
      position: absolute;
      right: 20px;
      top: 20px;
      color: #fff;
      font-size: 20px;
    }

    .global-color-option-inner {
      p {
        text-transform: uppercase;
        font-size: 13px;
        color: #fff;
        line-height: 1.2;
        margin-bottom: 25px;
      }

      .themes {
        display: flex;
        flex-wrap: wrap;
        padding-top: 30px;

        li {
          margin-right: 80px;
          margin-bottom: 55px;

          a {
            font-size: 14px;
            color: #fff;
            font-weight: 500;
            display: block;
            line-height: 1.2;
            position: relative;

            &::before {
              content: "";
              width: 100%;
              height: 1px;
              background: #fff;
              position: absolute;
              left: 0;
              top: 50%;
              opacity: 0;
              transition: 0.3s;
            }

            &:hover::before {
              opacity: 1;
            }
          }

          &.active a::before {
            opacity: 1;
          }
        }
      }
    }

    max-width: 1043px;
    width: 100%;
    margin-left: auto;
    background: #191919;
    height: 100%;
    overflow-x: hidden;
    padding-top: 50px;
    margin-right: -400px;
    transition: 0.4s;
    padding: 134px;

    h2 {
      color: #fff;
      font-weight: 200;
      font-size: 34px;
      line-height: 1.2;
      margin-bottom: 80px;
    }
  }

  &.active .inner .global-color-option {
    margin-right: 0;
  }

  .global-color-option .color-boxed {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 26px;
    margin-bottom: 105px;
    padding-top: 30px;

    a {
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      position: relative;
      background: #28e98c;

      &::before {
        content: "";
        border: 1px solid #fff;
        position: absolute;
        border-radius: 50%;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: 0.3s;
      }

      &::after {
        content: "";
        position: absolute;
        border: none;
        transform: translate(-50%, -50%);
        width: 4px;
        height: 4px;
        left: 50%;
        top: 50%;
        background: #fff;
        border-radius: 50%;
        opacity: 0;
      }

      &.clr-active::after {
        opacity: 1;
      }

      &:hover {
        &::after,
        &::before {
          opacity: 1;
        }
      }

      &.clr-active::before {
        opacity: 1;
      }

      &:nth-child(2) {
        background: #e4af12;
      }

      &:nth-child(3) {
        background: #fe6f1d;
      }

      &:nth-child(4) {
        background: #14c5fd;
      }

      &:nth-child(5) {
        background: #c0c0c0;
      }

      &:nth-child(6) {
        background: #1338f3;
      }

      &:nth-child(7) {
        background: #f31313;
      }

      &:nth-child(8) {
        background: #ff99cc;
      }

      &:nth-child(9) {
        background: #cceb00;
      }

      i {
        font-size: 14px;
      }
    }
  }
}

/* 04 - Header */

.header-area {
  background: #1f1f1f;
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 25px 0;

  .logo {
    display: block;

    img {
      display: block;
    }
  }

  nav {
    transition: 0.3s;

    .nav-menu {
      gap: 25px;
      display: flex;

      li {
        position: relative;

        .toggle-sub-dropdown,
        .toggle-dropdown {
          display: none;
          width: 35px;
          text-align: center;
        }

        .dropdown {
          position: absolute;
          left: 0;
          width: 200px;
          background: #000;
          box-shadow: 1px 6px 20px rgb(0 0 0 / 20%);
          transition: 0.3s;
          opacity: 0;
          visibility: hidden;
          padding: 5px 0;

          li a {
            padding: 8px 15px;
            line-height: 1.2;
          }
        }

        &:hover > .dropdown {
          opacity: 1;
          visibility: visible;
        }

        .dropdown li .dropdown {
          left: 100%;
          top: 0;
        }

        a {
          color: #fff;
          display: block;
          transition: 0.3s;
          padding: 8px 0;

          &:hover {
            color: var(--primary_color);
          }
        }
      }
    }
  }

  .header-right .show-menu-toggle {
    display: none;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
    border: 1px solid rgb(255 255 255 / 30%);
    margin-left: auto;
  }

  nav .close-menu {
    display: none;
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 30px;
    font-size: 25px;
    cursor: pointer;
    color: #fff;
  }
}

/* 05 - Section Header */

.section-header h1 {
  font-size: 48px;
  line-height: 60px;
  color: #fff;
  font-weight: 300;
  margin-bottom: 33px;

  span {
    color: var(--primary_color);
  }
}

/* 06 - Left Sidebar */

.left-sidebar {
  max-width: 485px;
  width: 100%;
  border-radius: 30px;
  border: 1px solid #565656;
  position: fixed;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  padding: 50px;
  z-index: 10;
}

.deniz-main .left-sidebar {
  display: none;
}

.left-sidebar {
  > img.me {
    border-radius: 30px;
    display: block;
    margin-bottom: 56px;
  }

  .sidebar-header {
    margin-bottom: 65px;

    .name {
      font-size: 20px;
      color: #fff;
      font-weight: 300;

      margin-bottom: 10px;
    }
    .designation {
      max-width: 110px;
      text-align: right;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
    }
  }

  h2 {
    font-size: 24px;
    color: #fff;
    text-align: center;
    font-weight: 300;
  }

  .address {
    margin-bottom: 30px;
  }

  .copyright {
    font-size: 14px;
    text-align: center;
    margin-bottom: 45px;
  }

  .social-profile {
    gap: 8px;
    margin-bottom: 118px;

    li a {
      display: block;
      width: 50px;
      height: 50px;
      line-height: 46px;
      text-align: center;
      border: 2px solid #565656;
      border-radius: 50%;
      color: #999999;
      font-size: 20px;
      transition: 0.3s;

      &:hover {
        color: var(--primary_color);
        border-color: var(--primary_color);
      }
    }
  }

  .theme-btn {
    width: 100%;
  }
}

/* 07 - Hero Section */

.hero-section {
  padding: 68px 0;
  position: relative;
  overflow: hidden;

  .hero-content {
    position: relative;
    z-index: 3;

    h1 {
      font-size: 78px;
      letter-spacing: -0.2px;
      line-height: 90px;
      margin-bottom: 43px;
      font-weight: 300;

      span {
        color: var(--primary_color);
      }
    }

    > p {
      max-width: 480px;
      margin-bottom: 31px;
    }

    .go-to-project-btn {
      width: 175px;
      height: 175px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      border-radius: 50%;
      border: 1px solid #575757;
      position: relative;
      overflow: hidden;
      margin-right: 15px;

      img {
        display: block;
        animation: rotating 6s infinite linear;
      }

      i {
        position: absolute;
        color: #fff;
        font-size: 40px;
        display: block;
      }
    }

    .facts {
      gap: 100px;
      margin-top: 55px;

      h1 {
        font-size: 72px;
        color: var(--primary_color);
        line-height: 56px;
        margin-bottom: 38px;
      }

      p {
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;
      }
    }
  }
}

/* 08 - Home 3 */

.home1-page,
.home5-page,
.home6-page {
  background: #000;
}

.home-3 {
  overflow: hidden;

  &:before {
    content: "";
    background: #000;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.6;
  }
}

.home1-page .menu,
.home5-page .menu,
.home6-page .menu,
.home1-page .icon-menu,
.home5-page .icon-menu,
.home6-page .icon-menu {
  background: #000000;
}

/* 09 - Home 5 */

body .body-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.opacity-01 {
  opacity: 0.1;
}

.home2-page .body-overlay,
.home3-page .body-overlay {
  opacity: 0.1;
}

.home1-page .body-overlay,
.home4-page .body-overlay,
.home5-page .body-overlay {
  opacity: 0.3;
}

/* 10 - About */

.about-area {
  padding-top: 90px;
  padding-bottom: 90px;

  .about-content p {
    line-height: 30px;
    max-width: 610px;
  }
}

/* 11 - Resume */

.resume-area {
  padding-top: 90px;
  padding-bottom: 90px;

  .resume-content .resume-timeline .item {
    position: relative;
    padding-left: 74px;
    padding-bottom: 68px;

    &:last-child {
      padding-bottom: 0;

      &::after {
        height: calc(100% - 10px);
      }
    }

    &::after {
      content: "";
      background: #333333;
      width: 1px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 10px;
      z-index: -1;
    }

    &::before {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      background: #656565;
      border-radius: 50%;
      left: -6px;
      top: 7px;
      transition: 0.3s;
    }

    .date {
      display: block;
      margin-bottom: 28px;
      transition: 0.3s;
    }

    h2 {
      font-size: 24px;
      font-weight: 300;
      margin-bottom: 5px;
      color: #fff;

      a {
        color: #fff;
        display: block;
      }
    }

    p {
      font-size: 13px;
      color: #999999;
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:hover {
      &::before {
        background: var(--primary_color);
      }

      .date {
        color: var(--primary_color);
      }
    }
  }
}

/* 12 - Services */

.services-area {
  padding-top: 90px;
  padding-bottom: 90px;
}

.services-items .service-item {
  border: 1px solid #565656;
  border-radius: 20px;
  transition: 0.3s;
  position: relative;
  padding: 44px 48px 41px 48px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  h2 {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 8px;
    color: #fff;

    a {
      color: #fff;
      transition: 0.3s;
    }
  }

  &:hover {
    border-color: var(--primary_color);

    h2 a {
      color: var(--primary_color);
    }
  }

  p {
    font-size: 14px;
    color: #999999;
    margin-bottom: 30px;
  }

  .projects {
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    display: inline-block;
  }

  &:hover .projects {
    text-decoration: underline;
  }

  i {
    position: absolute;
    font-size: 30px;
    color: var(--primary_color);
    top: 45px;
    right: 50px;
  }
}

/* 13 - Skills */

.skills-area {
  padding-top: 90px;
  padding-bottom: 40px;
}

.skills .skill {
  margin-bottom: 50px;

  .skill-inner {
    border: 2px solid #565656;
    border-radius: 85px;
    padding: 54px 0 48px 0;
    margin-bottom: 20px;
    transition: 0.3s;
  }

  &:hover .skill-inner {
    border-color: var(--primary_color);
  }

  .skill-inner {
    img {
      display: block;
      margin: auto auto 29px auto;
    }

    h1 {
      font-size: 30px;
      color: var(--primary_color);
      font-weight: 300;
      margin: 0;
    }
  }

  p {
    font-size: 14px;
    color: #fff;
    margin: 0;
  }
}

/* 14 - Portfolio */

.portfolio-area {
  padding-top: 90px;
  padding-bottom: 90px;
}

.portfolio-items {
  .portfolio-item {
    margin-bottom: 62px;

    .portfolio-item-inner {
      height: 370px;
      overflow: hidden;
      border-radius: 30px;
      position: relative;
      margin-bottom: 30px;
    }

    &.portfolio-full .portfolio-item-inner {
      height: 410px;
    }

    .portfolio-item-inner {
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .portfolio-categories {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
        bottom: 20px;
        left: 20px;

        li a {
          display: block;
          font-size: 14px;
          background: #fff;
          color: #000;
          padding: 7px 20px;
          border-radius: 19px;
          transition: 0.3s;
        }
      }
    }

    &:hover .portfolio-item-inner .portfolio-categories li a {
      background: #1f1f1f;
      color: #fff;
    }

    h2 {
      font-size: 24px;
      font-weight: 300;
      margin: 0;

      a {
        color: #fff;
        display: inline-block;
        border-bottom: 1px solid transparent;
        transition: 0.3s;
      }
    }

    &:hover h2 a {
      border-color: #ffffff;
    }
  }

  > div:last-child .portfolio-item {
    margin-bottom: 0;
  }
}

/* 15 - Testimonial */

.testimonial-item .testimonial-item-inner {
  border: 2px solid #565656;
  border-radius: 30px;
  padding: 50px 50px 43px 50px;

  .author {
    gap: 16px;
    margin-bottom: 45px;

    img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      object-fit: cover;
    }

    h3 {
      font-size: 18px;
      font-weight: 300;
      color: #fff;
      margin-bottom: 6px;
    }

    p {
      color: #999999;
      font-size: 13px;
      margin: 0;
      line-height: 1.3;

      span {
        color: var(--primary_color);
      }
    }
  }

  > p {
    font-size: 24px;
    color: #fff;
    font-weight: 300;
    line-height: 36px;
    margin-bottom: 27px;
  }

  .project-btn {
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    display: inline-block;
  }
}

.testimonial-area .testimonial-slider-wrap .testimonial-footer-nav {
  margin-top: 50px;

  .testimonial-nav {
    gap: 15px;

    button {
      width: 45px;
      height: 45px;
      display: block;
      background: none;
      border-radius: 50%;
      text-align: center;
      border: 2px solid #565656;
      font-size: 18px;
      color: #fff;
      transition: 0.3s;

      &:hover {
        border-color: var(--primary_color);
        color: var(--primary_color);
      }
    }

    #testimonial-slide-count {
      font-size: 13px;
      color: #666666;
      font-weight: 300;

      .left {
        color: #fff;
      }
    }
  }
}

/* 16 - Client */

.clients-logos {
  padding-top: 100px;

  h4 {
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 57px;
  }

  .col-md-3 {
    margin-bottom: 74px;
  }

  img {
    display: block;
    opacity: 0.3;
    transition: 0.3s;

    &:hover {
      opacity: 1;
    }
  }
}

/* 17 - Pricing Table */

.pricing-area {
  padding-top: 90px;
  padding-bottom: 90px;
}

.pricing-table-items {
  .pricing-table {
    border: 1px solid #565656;
    border-radius: 30px;
    padding: 37px 40px 40px 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.3s;

    &:hover {
      border-color: var(--primary_color);
    }

    .pricing-table-header {
      border-bottom: 1px solid #555555;
      padding-bottom: 20px;
      margin-bottom: 34px;

      .top {
        margin-bottom: 30px;
      }

      h4 {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 300;
        color: #fff;
        margin: 0;
      }

      .top p {
        font-size: 13px;
        line-height: 20px;
        color: #999999;
        margin: 0;
      }

      h1 {
        font-size: 48px;
        color: var(--primary_color);
        font-weight: 300;
        margin: 0;

        span {
          font-size: 24px;
          color: #999999;
        }
      }
    }

    ul {
      margin-bottom: 80px;

      li {
        font-size: 14px;
        color: #fff;
        line-height: 24px;
        font-weight: 300;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .info {
    font-size: 14px;
    color: #fff;
    line-height: 24px;
    margin: 60px 0 0 0;

    a {
      color: var(--primary_color);
      text-decoration: underline;
    }
  }
}

/* 18 - Contact */

.contact-area {
  padding-bottom: 50px;

  .contact-content {
    h3 {
      font-size: 24px;
      color: #fff;
      font-weight: 300;
      margin-bottom: 60px;
    }

    #required-msg {
      color: #fc4545;
      font-size: 14px;
      margin-bottom: 31px;
      display: none;

      &.show {
        display: block;
      }
    }

    .contact-form .input-group {
      margin-bottom: 34px;

      label {
        display: block;
        font-size: 12px;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 2px;

        sup {
          color: #fc4545;
          font-size: 12px;
          top: 0;
        }

        span {
          color: #999999;
          text-transform: none;
        }
      }

      select,
      textarea,
      input {
        display: block;
        color: #666666;
        width: 100%;
        border: none;
        background: none;
        font-size: 18px;
        padding: 0;
        transition: 0.2s;
        padding-bottom: 3px;
      }

      textarea {
        height: 140px;
        border-bottom: 1px solid #555555;
        resize: none;
      }

      select {
        display: block;
        margin-left: -5px !important;
        font-weight: 300;

        &:focus {
          outline: none;
          box-shadow: none;
          border-color: #555555;
        }
      }

      textarea:focus,
      input:focus {
        outline: none;
        box-shadow: none;
        border-color: #555555;
      }

      textarea::placeholder,
      input::placeholder {
        color: #666666;
      }

      &.upload-attachment {
        position: relative;

        label {
          overflow: hidden;
          position: relative;
          display: inline-flex;
          align-items: center;
          gap: 10px;

          i {
            font-size: 18px;
            display: block;
            margin-bottom: 2px;
          }
        }

        input {
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
        }
      }

      &.submit-btn-wrap {
        margin-bottom: 0;
        margin-top: 18px;
      }
    }
  }
}

/* 19 - Breadcrumb */

.breadcrumb-area {
  position: relative;
  padding: 50px 0px 50px;
  background: #1f1f1f;
  overflow: hidden;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    left: -50px;
    top: -50px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: rgb(255 255 255 / 10%);
    z-index: -1;
    box-shadow: 0px 0px 0 20px rgb(255 255 255 / 5%);
  }

  &::after {
    content: "";
    position: absolute;
    left: -50px;
    top: -50px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: rgb(255 255 255 / 10%);
    z-index: -1;
    box-shadow: 0px 0px 0 20px rgb(255 255 255 / 5%);
    left: auto;
    right: -50px;
    top: auto;
    bottom: -50px;
  }

  .breadcrumb-content {
    h1 {
      font-size: 45px;
      color: #fff;
      font-weight: 300;
      margin-bottom: 20px;
    }

    .breadcrumbs {
      display: flex;
      align-items: center;
      gap: 10px;

      li {
        text-transform: uppercase;
        color: #d1d1d1;
        font-size: 14px;
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;

        i {
          display: block;
          margin-bottom: 1px;
        }

        &:last-child::before {
          display: none;
        }

        a {
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
}

/* 20 - Blog items */

.blog-content-area {
  padding-top: 100px;
  padding-bottom: 60px;

  .blog-items {
    padding-right: 20px;
  }
}

.blog-item {
  margin-bottom: 40px;

  .blog-thumbnail {
    margin-bottom: 30px;

    img {
      display: block;
      width: 100%;
      border-radius: 6px;
    }
  }

  .meta {
    display: flex;
    align-items: center;
    gap: 35px;
    margin-bottom: 10px;

    li {
      text-transform: uppercase;
      font-size: 14px;
      position: relative;
      line-height: 1.2;

      &::before {
        content: "";
        position: absolute;
        right: -21px;
        top: 7px;
        background: #808080;
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }

      &:last-child::before {
        display: none;
      }

      a {
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 5px;
        transition: 0.3s;

        /* opacity: 0.5; */
        color: #fff;
        background: linear-gradient(
          to top right,
          var(--primary_color) -200%,
          transparent 300%
        );
        background-repeat: no-repeat;
        background-size: 0 0.0625em;
        background-position: 0 95%;
        transition: 0.25s;

        &:hover {
          background-size: 100% 0.3625em;
        }

        i {
          display: block;
          font-size: 18px;
          margin-bottom: 1px;
          color: var(--primary_color);
        }
      }
    }
  }

  .blog-item-content h1 {
    font-size: 28px;
    line-height: 1.2;
    margin-bottom: 20px;

    a {
      color: #fff;
      display: inline-block;
      background: linear-gradient(
        to top right,
        var(--primary_color) -200%,
        transparent 300%
      );
      background-repeat: no-repeat;
      background-size: 0 0.0625em;
      background-position: 0 95%;
      transition: 0.25s;

      &:hover {
        background-size: 100% 0.3625em;
      }
    }
  }

  p {
    /* color: #686a6f; */
    line-height: 1.8;
    margin-bottom: 15px;
  }

  .blog-item-content .read-more-btn {
    display: inline-block;
    text-transform: uppercase;
    position: relative;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.4px;
    border-bottom: 2px solid var(--primary_color);
    padding: 4px 0;
    transition: 0.3s;

    &:hover {
      border-color: #fff;
      color: var(--primary_color);
    }
  }
}

.blog-sidebar .blog-sidebar-item {
  padding: 40px;
  background: #2a2a2a;
  margin-bottom: 40px;
  border-radius: 4px;

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1;
    color: #ffffff;
    margin-bottom: 30px;
    position: relative;
    display: inline-block;

    &::before {
      content: "";
      width: 50px;
      height: 2px;
      background: var(--primary_color);
      right: -60px;
      position: absolute;
      top: 11px;
    }
  }

  ul li {
    line-height: 1.6;
    padding: 10px 0;
    border-bottom: 1px solid #686a70;
    margin: 3px 0;

    &:first-child {
      margin-top: 0;
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }

    a {
      display: block;
      color: #ffffff;
      font-weight: 300;
      transition: 0.3s;

      &:hover {
        color: var(--primary_color);
      }
    }
  }

  &.blog-sidebar-search {
    padding: 0;
    background: none;
  }
}

.search-form {
  display: block;
  position: relative;

  input {
    display: block;
    width: 100%;
    line-height: 40px;
    padding: 19px 80px 19px 35px;
    background: #2a2a2a;
    border-radius: 4px;
    border: 1px solid transparent;
    font-weight: 300;
    transition: 0.3s;
    color: #fff;

    &:focus {
      box-shadow: none;
      outline: none;
      border-color: var(--primary_color);
    }
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border: none;
    background: none;
    cursor: pointer;
    width: 70px;
    font-size: 25px;
    color: #fff;
  }
}

.blog-sidebar .blog-sidebar-item.blog-tags ul {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;

  li {
    padding: 0;
    margin: 0;
    border: none;

    a {
      background: #fff;
      padding: 6px 18px;
      color: #000;
      font-weight: 400;
      border-radius: 30px;
      font-size: 14px;

      &:hover {
        background: #000;
        color: #fff;
      }
    }
  }
}

/* 21 - Blog Style 2 */

.blog-item2 {
  text-align: center;

  .blog-thumbnail {
    height: 255px;
    margin-bottom: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .blog-item-content {
    position: relative;
    margin-top: -22px;
    padding: 0 20px;
  }

  .meta {
    margin: 0;
    background: #2a2a2a;
    padding: 12px 20px;
    border-radius: 4px;
    display: inline-flex;
    box-shadow: 0px 10px 30px 0px rgb(0 0 0 / 5%);
    margin-bottom: 20px;

    li {
      font-size: 12px;

      a {
        color: #a0a0a0;

        &:hover {
          color: #fff;
        }

        background: none;
      }
    }
  }

  .blog-item-content {
    h1 {
      a {
        background: none;

        &:hover {
          color: var(--primary_color);
        }
      }

      font-size: 25px;
    }

    .read-more-btn {
      font-size: 14px;
    }
  }
}

/* 22 - Blog Details */

.blog-details-area {
  padding-top: 100px;
  padding-bottom: 100px;

  .blog-details-content {
    .meta {
      margin-bottom: 15px;
    }

    p {
      font-size: 15px;
      color: #999999;
      color: #fff;
      font-weight: 300;
    }

    h3 {
      color: #ffffff;
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    .lists {
      margin-bottom: 1rem;

      li {
        display: block;
        position: relative;
        font-size: 15px;
        line-height: 32px;
        padding-left: 25px;

        &::before {
          content: "";
          background: #999999;
          width: 10px;
          height: 2px;
          position: absolute;
          top: 15px;
          left: 5px;
        }
      }
    }

    .tags {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 40px;

      li a {
        display: block;
        background: #fff;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        padding: 7px 20px;
        border-radius: 30px;
        transition: 0.3s;

        &:hover {
          background: #000000;
          color: #fff;
        }
      }
    }
  }
}

/* 23 - Comment */

.comments-area {
  h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
    line-height: 1.2;
  }

  .comments {
    .comments-list .comment-item {
      margin-bottom: 40px;

      .comment-body {
        display: flex;
        align-items: flex-start;
        gap: 30px;

        .comment-avatar {
          flex: none;
          width: 90px;
          height: 90px;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .comment-content {
          flex: 1;

          h4 {
            color: #fff;
            font-size: 20px;
            line-height: 1.1;
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .date {
              display: block;
              font-size: 12px;
              font-weight: 400;
              margin-left: 40px;

              /* color: #999999; */
              position: relative;
              text-transform: uppercase;
              color: var(--primary_color);

              &::before {
                content: "";
                width: 20px;
                height: 1px;
                position: absolute;
                left: -25px;
                top: 5px;

                /* background: #999999; */
                background: var(--primary_color);
              }
            }
          }

          p {
            color: #686a6f;
            margin: 0;
          }

          .reply-btn {
            text-transform: uppercase;
            padding: 7px 20px;
            font-size: 12px;
            margin-top: 20px;
          }
        }
      }
    }

    .children {
      padding-left: 60px;
      border-top: 1px solid #686a70;
      padding-top: 40px;
    }
  }
}

/* 24 - Comment Form */

.comment-form {
  padding-top: 20px;

  h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
    line-height: 1.2;
  }

  form .input-group {
    margin-bottom: 20px;

    input,
    textarea {
      font-size: 14px;
      display: block;
      color: #fff;
      width: 100%;
      border: none;
      background: none;
      padding: 0;
      transition: 0.2s;
      padding-bottom: 3px;
    }

    input:focus {
      outline: none;
      box-shadow: none;
    }

    textarea {
      &:focus {
        outline: none;
        box-shadow: none;
      }

      height: 180px;
      border-bottom: 1px solid #555555;
      resize: none;
    }
  }
}

/* 25 - Footer */

.footer-area {
  background: #1f1f1f;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-top: 100px;
  padding-bottom: 100px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    /*background: url("bg-shape.png") center no-repeat;*/
    background-size: cover;
    opacity: 0.4;
  }

  .footer-widget-item {
    h3 {
      color: #fff;
      text-transform: capitalize;
      font-weight: 600;
      margin-bottom: 30px;
      font-size: 20px;
    }

    p {
      color: #cccccc;
      font-size: 15px;
    }

    .social-links {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 30px;
      margin-top: 30px;

      li a {
        color: #ccc;
        font-size: 18px;
        transition: 0.3s;

        &:hover {
          color: #fff;
        }

        i {
          display: block;
        }
      }
    }

    &.footer-links ul li {
      + li {
        margin-top: 15px;
      }

      a {
        color: #ccc;
        font-size: 15px;
        display: block;
        transition: 0.3s;

        &:hover {
          color: #fff;
        }
      }
    }

    &.footer-contact-widget ul li {
      display: block;

      + li {
        margin-top: 15px;
      }

      .title {
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;
        display: block;
        position: relative;
        z-index: 1;
        font-size: 14px;
        margin-bottom: 5px;
        line-height: 1.2;
      }

      .sub-title {
        color: #cccccc;
        font-size: 15px;
      }
    }
  }
}

/* @Keyframe */
@keyframes rotating {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounceLoader {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }

  35% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0;
  }
}
