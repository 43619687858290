@media (max-width: 2000px) {
  .left-sidebar {
    max-width: 400px;

    .sidebar-header,
    .social-profile {
      margin-bottom: 30px;
    }

    .copyright {
      margin-bottom: 25px;
    }
  }

  .menu {
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (max-width: 1555px) {
  .left-sidebar {
    max-width: 350px;
    padding: 30px;

    > img.me {
      width: 100%;
      max-width: 250px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 46px;
    }

    .sidebar-header .designation {
      line-height: 1.4;
    }
  }
}

@media (max-width: 1220px) {
  .custom-container {
    max-width: 767px;
  }

  .content-width {
    max-width: 100%;
  }

  .section-header h1 br {
    display: none;
  }

  .left-sidebar {
    position: relative;
    max-width: 767px;
    left: 0;
    margin: auto;
    top: 0;
    transform: translateY(0);
    margin-top: 20px;
  }

  .deniz-main .left-sidebar {
    display: block;
  }

  .left-sidebar > img.me {
    width: auto;
  }

  .icon-menu {
    position: fixed;
  }

  .menu {
    display: none !important;
  }

  .responsive-sidebar-menu .sidebar-menu-inner .menu-wrap .menu {
    display: flex !important;
  }

  .resume-area {
    padding-bottom: 50px;
  }

  .about-area {
    padding-bottom: 50px;

    .about-content p {
      max-width: 100%;
    }
  }

  .testimonial-area {
    padding-top: 50px;
  }

  .services-items {
    display: flex;
    gap: 15px;

    .service-item {
      margin-bottom: 0;
      padding: 30px 30px 30px 30px;
      width: 33.3333%;

      h2 {
        font-size: 20px;
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 25px;
      }

      i {
        font-size: 20px;
        top: 25px;
        right: 20px;
      }
    }
  }

  .pricing-table-items .pricing-table .theme-btn {
    padding: 11px 40px 10px 40px;
  }
}

@media (max-width: 977px) {
  .blog-three-columns-main .blog-content-area .row > .col-md-4 {
    width: 50%;
  }
}

@media (max-width: 787px) {
  .hero-section .hero-content {
    h1 {
      font-size: 58px;
      line-height: 1.3;
    }

    .facts h1 {
      font-size: 52px;
    }
  }
}

@media (max-width: 767px) {
  .icon-menu {
    right: 30px;
    top: 30px;
  }

  .hero-section .hero-content .go-to-project-btn {
    margin: 0 auto;
  }

  .skills-area .skills-content .row .col-md-3 {
    flex: 0 0 auto;
    width: 50%;
  }

  .clients-logos .row .col-md-3 {
    flex: 0 0 auto;
    width: 33.3333%;
    margin-bottom: 54px;
  }

  .pricing-area {
    padding-top: 50px;
  }

  .pricing-table-items .pricing-table {
    margin-bottom: 15px;
    display: block;
    height: auto;

    .theme-btn {
      width: 100%;
      padding: 13px 20px;
    }

    ul {
      margin-bottom: 50px;
    }
  }

  .contact-area .contact-content h3 {
    margin-bottom: 50px;
  }

  .header-area {
    .custom-container .row .col-sm-6 {
      flex: 0 0 auto;
      width: 50%;
    }

    .header-right {
      .show-menu-toggle {
        display: flex;
      }

      nav {
        position: fixed;
        right: 0;
        max-width: 350px;
        width: 100%;
        height: 100%;
        z-index: 100;
        background: #1f1f1f;
        padding: 60px 40px 40px 40px;
        overflow-x: hidden;
        top: 0;
        margin-right: -300px;
        opacity: 0;
        visibility: hidden;

        &.active {
          opacity: 1;
          margin-right: 0;
          visibility: visible;
        }
      }
    }

    nav .nav-menu li + li {
      margin-top: 10px;
    }

    .header-right nav .nav-menu {
      display: block;
    }

    nav {
      .close-menu {
        display: block;
      }

      .nav-menu li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        a {
          flex: 1;
        }

        .dropdown {
          position: relative;
          visibility: visible;
          opacity: 1;
          width: 100%;
          display: none;

          li {
            a {
              font-size: 14px;
            }

            margin: 0;
          }
        }

        .toggle-sub-dropdown,
        .toggle-dropdown {
          display: block;
        }

        .dropdown li .dropdown {
          padding-left: 15px;
          left: 0;
        }

        &.active > .dropdown {
          display: block;
        }
      }
    }
  }

  .global-color {
    .inner .global-color-option {
      h2 {
        margin-bottom: 60px;
      }

      padding: 100px 50px;
    }

    .global-color-option .color-boxed {
      margin-bottom: 50px;
      padding-top: 10px;
    }

    .inner .global-color-option .global-color-option-inner .themes {
      padding-top: 10px;

      li {
        margin-right: 40px;
        margin-bottom: 20px;
      }
    }
  }

  .services-items {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .service-item {
      width: 100%;
    }
  }

  .blog-sidebar {
    margin-top: 50px;
  }

  .blog-three-columns-main .blog-content-area .row > .col-md-4 {
    width: 100%;
  }

  .footer-area {
    padding-bottom: 60px;

    .footer-widget-item {
      margin-bottom: 40px;
    }
  }
}

@media (max-width: 584px) {
  .subtitle {
    margin-bottom: 40px;
  }

  .section-header h1 {
    font-size: 38px;
    line-height: 1.4;
  }

  .skills-area,
  .services-area,
  .resume-area,
  .about-area {
    padding-bottom: 0;
  }

  .hero-section {
    padding-bottom: 0;

    .hero-content {
      h1 {
        font-size: 50px;
      }

      .facts {
        h1 {
          font-size: 48px;
          margin-bottom: 25px;
        }

        p br {
          display: none;
        }

        gap: 20px;
      }
    }
  }

  .resume-area .resume-content .resume-timeline .item {
    padding-left: 50px;
  }

  .services-items {
    grid-template-columns: 1fr;
  }

  .portfolio-area {
    padding-top: 40px;
  }

  .clients-logos .row .col-md-3 {
    width: 50%;
  }

  .contact-area {
    padding-bottom: 80px;
  }

  .breadcrumb-area .breadcrumb-content h1 {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .comments-area .comments {
    .comments-list .comment-item .comment-body {
      gap: 15px;

      .comment-avatar {
        width: 60px;
        height: 60px;
      }

      .comment-content h4 {
        margin-bottom: 15px;
      }
    }

    .children {
      padding-left: 40px;
    }
  }
}

@media (max-width: 425px) {
  .comments-area
    .comments
    .comments-list
    .comment-item
    .comment-body
    .comment-content
    h4 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .date {
      margin-left: 26px;
      margin-top: 10px;
    }
  }
}
